@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";

.ag-theme-material {
  @include ag-theme-material((
    header-background-color: #f2f2f2,
    grid-size: 5px
  ));
}

.ag-disable-cell-highlight {
  &.ag-cell-focus, &.ag-cell-no-focus {
    border-color: transparent !important;
  }

  &.ag-cell:focus {
    border-color: transparent !important;
    outline: none;
  }
}

@keyframes pageFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pageFadeIn {
  animation: pageFadeIn 1s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.pulse {
  animation: pulse;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@import '~styles/themes/default.less';
@import '~styles/animations.less';

.wrapper {
  align-items: center;
  background-color: #FFF;
  box-shadow: @default-box-shadow;
  display: flex;
  flex: 0 0 auto;
  line-height: 1;
  height: 66px;
  padding: 0 @indent * 1.3;
}

.pageTitle {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin-left: @indent;
  .pageFadeIn()
}

.headerIcon {
  cursor: pointer;
  font-size: 19px;
}

.leftSide {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
}

.userProfileInfo {
  align-items: center;
  column-gap: @indent / 2;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.headerRow {
  align-items: center;
  display: grid;
  grid-gap: @indent;
  grid-template-columns: 1fr auto;
  width: 100%;
}

.notOpened {
  color: @red-6;
}

.clickable {
  cursor: pointer;
}

@btn-border-radius-base: 0;@accent-color: #ffc200;@primary-color: #023e7f;@grey-color: #eee;@grey-text: #bebebe;@grey-text-2: #aeaeae;@grey-text-3: #9e9e9e;@grey-text-4: #8e8e8e;@error-color: lighten(#f5222d, 15%);@btn-danger-colors: lighten(#f5222d, 10%);@btn-danger-bg: @btn-danger-colors;@btn-danger-border: @btn-danger-colors;@item-active-bg: @grey-color;@item-hover-bg: @grey-color;@table-row-hover-bg: @grey-color;@focused-cell-border-color: #3f51b5;@default-box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .1);@default-border-radius: 4px;@indent: 16px;@normal-resolution: 1700px;@breakpoint-sm: 540px;@breakpoint-md: 720px;@breakpoint-lg: 960px;@breakpoint-xl: 1140px;
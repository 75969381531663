@import '~styles/themes/default.less';

:global {
  body {
    overflow: hidden;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  box-shadow: @box-shadow-base;
  padding: @indent;

  :global {
    .@{ant-prefix}-form-item {
      width: 100%;
    }
  }
}

@btn-border-radius-base: 0;@accent-color: #ffc200;@primary-color: #023e7f;@grey-color: #eee;@grey-text: #bebebe;@grey-text-2: #aeaeae;@grey-text-3: #9e9e9e;@grey-text-4: #8e8e8e;@error-color: lighten(#f5222d, 15%);@btn-danger-colors: lighten(#f5222d, 10%);@btn-danger-bg: @btn-danger-colors;@btn-danger-border: @btn-danger-colors;@item-active-bg: @grey-color;@item-hover-bg: @grey-color;@table-row-hover-bg: @grey-color;@focused-cell-border-color: #3f51b5;@default-box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .1);@default-border-radius: 4px;@indent: 16px;@normal-resolution: 1700px;@breakpoint-sm: 540px;@breakpoint-md: 720px;@breakpoint-lg: 960px;@breakpoint-xl: 1140px;
@import '~styles/themes/default.less';
@import '~styles/animations.less';

:global {
  body {
    overflow: hidden;
  }

  .@{ant-prefix}-dropdown-menu-inline-collapsed-tooltip {
    display: none;
  }
}

.wrapper {

}

.outerLayer {
  display: grid;
  height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "side head"
    "side main"
    "side main"
}

.side {
  background: @primary-color;
  grid-area: side;
  z-index: 5;
}

.sider {
  background-color: inherit !important;
  height: 100%;

  :global {
    .@{ant-prefix}-layout-sider-children {
      display: grid;
      grid-template-rows: auto 1fr auto auto;
    }
  }
}

.head {
  background-color: @grey-color;
  grid-area: head;
  z-index: 2;
}

.main {
  background-color: @grey-color;
  grid-area: main;
  overflow: auto;
  z-index: 1;
}

.content {
  padding: @indent;
}

.contentInnerScrollbar {
  height: 100%;
}

.contentSide {

}

.innerLayer {
  height: 100%;
  .pageFadeIn()
}

.inner {
  background-color: @white;
  border-radius: @default-border-radius;
  box-shadow: @default-box-shadow;
  max-height: 100%;
  padding: @indent;
}

.innerFullHeight {
  height: 100%;
}

.appVersionWidth {
  width: 80px;
}

.red {
  background-color: rgba(@red-6, 0.5);
  color: @red-6;
  cursor: pointer;
}

@btn-border-radius-base: 0;@accent-color: #ffc200;@primary-color: #023e7f;@grey-color: #eee;@grey-text: #bebebe;@grey-text-2: #aeaeae;@grey-text-3: #9e9e9e;@grey-text-4: #8e8e8e;@error-color: lighten(#f5222d, 15%);@btn-danger-colors: lighten(#f5222d, 10%);@btn-danger-bg: @btn-danger-colors;@btn-danger-border: @btn-danger-colors;@item-active-bg: @grey-color;@item-hover-bg: @grey-color;@table-row-hover-bg: @grey-color;@focused-cell-border-color: #3f51b5;@default-box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .1);@default-border-radius: 4px;@indent: 16px;@normal-resolution: 1700px;@breakpoint-sm: 540px;@breakpoint-md: 720px;@breakpoint-lg: 960px;@breakpoint-xl: 1140px;